// Util to build API url from path and params
import qs from "qs";
import { ref } from "vue";

const API_URL = import.meta.env.VITE_API_URL;

export function api(url: string, params?: Record<string, any>): string {
    url = `${API_URL}/api${url}`;
    if (params) {
        url += `?${qs.stringify(params)}`;
    }
    return url;
}

export async function useFetch(url: string, options?: RequestInit) {
    const data = ref(null);
    const error = ref(null);

    fetch(url, options)
        .then(res => res.json())
        .then(json => (data.value = json))
        .catch(err => (error.value = err));

    return { data, error };
}

import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { api } from "@/lib/api";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

export interface ApiErrorResponse {
    message: string;
    statusCode: number;
    error: string;
    [key: string]: any;
}

class ApiError extends Error {
    statusCode: number;
    details: any;

    constructor(message: string, statusCode: number, details?: any) {
        super(message);
        this.name = "ApiError";
        this.statusCode = statusCode;
        this.details = details;
    }
}

interface FetchWithOrgHeaderOptions extends RequestInit {
    forceOrganizationId?: string;
}

export const fetchWithOrgHeader = async <T = any>(url: string, options: FetchWithOrgHeaderOptions = {}): Promise<T> => {
    const { forceOrganizationId, ...fetchOptions } = options;
    const authStore = useAuthStore();
    const { isSystemAdmin, isSystemAdminOrganisation } = useUserPrivileges();
    const { getAccessToken } = authStore;
    const { selectedOrganization } = storeToRefs(authStore);

    const headers = new Headers(fetchOptions.headers);
    const accessToken = getAccessToken();
    if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
    }

    const orgId =
        forceOrganizationId || (isSystemAdmin.value && isSystemAdminOrganisation.value)
            ? "org_sysadmin"
            : selectedOrganization.value?.id;
    if (orgId) {
        headers.set("x-organization-id", orgId);
    }

    const response = await fetch(api(url), {
        ...fetchOptions,
        headers,
    });

    if (!response.ok) {
        let errorData: ApiErrorResponse;
        try {
            errorData = await response.json();
        } catch {
            throw new ApiError(`HTTP error! status: ${response.status}`, response.status);
        }
        throw new ApiError(errorData.message || `HTTP error! status: ${response.status}`, response.status, errorData);
    }

    if (fetchOptions.method === "DELETE") {
        // @ts-ignore
        return;
    }

    try {
        return await response.json();
    } catch (error) {
        throw new ApiError("Failed to parse response", response.status);
    }
};
